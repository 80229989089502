<template>
  <v-container fluid class="mb-10">
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card elevation="0">
          <v-col cols="12">
            <v-card class="ma-10" elevation="0">
              <v-row class="mb-5">
                <!-- :disabled="
                      !orderInfo.can_be_confirmed || orderInfo.is_processing
                    " -->
                <v-col>
                  <div class="font-weight-medium">Delivery Address</div>
                  <div v-if="orderInfo.billing_address">
                    <v-icon>mdi-map-marker</v-icon
                    >{{ orderInfo.billing_address.address }}
                  </div>
                  <!-- v-if="orderInfo.can_be_confirmed && orderInfo.admin_status == 'Pending'" -->
                  <v-btn
                    class="mt-10"
                    color="green"
                    elevation="0"
                    :disabled="showConfirmButton"
                    @click="onConfirmOrder"
                    >Confirm</v-btn
                  >
                  <!-- v-if="orderInfo.can_be_confirmed && orderInfo.admin_status == 'Pending'" -->
                  <v-btn
                    class="mt-10"
                    color="red"
                    elevation="0"
                    :disabled="showCancelButton"
                    @click="orderCancel"
                    >Cancel</v-btn
                  >
                </v-col>
                <v-col class="text-right">
                  <p class="mb-0">Order ID: {{ orderInfo.id }}</p>
                  <p class="mb-0">
                    Payment Status: {{ orderInfo.order_status }}
                  </p>
                  <p class="mb-0">
                    Delivery Status: {{ orderInfo.delivery_status }}
                  </p>
                  <p class="mb-0" v-if="orderInfo.user_id != -1">
                    User ID: {{ orderInfo.user_id }}
                  </p>
                  <p class="mb-0" v-else>
                    Non Verified ID: {{ orderInfo.non_verified_user_id }}
                  </p>
                </v-col>
              </v-row>

              <v-data-table
                :headers="headers"
                :items="products"
                :loading="loading"
                class="elevation-1"
              >
                <template v-slot:top> </template>

                <template v-slot:item.actions="{ item }">
                  <div
                    v-if="
                      (item.is_own && !item.is_seller) ||
                      (item.is_seller &&
                        item.delivery_assigned == 'Admin' &&
                        item.is_delivery_assigned)
                    "
                  >
                    <div v-if="item.admin_status == 'Pending'">
                      <v-btn icon @click="Approve(item)" dark>
                        <v-icon color="primary"
                          >mdi-checkbox-marked-circle</v-icon
                        >
                      </v-btn>

                      <v-btn icon @click="CancelProduct(item)" dark>
                        <v-icon color="red" class="pa-2"
                          >mdi-close-circle</v-icon
                        >
                      </v-btn>
                    </div>

                    <div v-else>
                      <p class="mb-0 font-weight-medium text-left">
                        {{ item.admin_status }}
                      </p>
                    </div>
                  </div>

                  <!--
                  <div v-else>
                    <p class="mb-0">
                      {{ item.admin_status }}
                      <v-icon @click="onDetails(item)">mdi-check</v-icon>
                    </p>
                  </div> -->
                </template>

                <template v-slot:item.payment_actions="{ item }">
                  <div class="d-flex align-center justify-content-between">
                    <div class="mx-1">
                      {{ item.order_status }}
                    </div>

                    <!-- <v-icon
                      @click="openPaymentmodal(item)"
                      class="mx-1"
                      v-if="
                        item.order_status == 'Unpaid' &&
                        orderStatus == 'Confirmed' &&
                        item.is_own &&
                        !item.is_seller
                      "
                      >mdi-pencil</v-icon
                    > -->
                  </div>
                </template>

                <template v-slot:item.deliveryaction="{ item }">
                  <div class="d-flex align-center justify-content-between">
                    <div class="mx-1">
                      {{ item.delivery_status }}
                    </div>

                    <!-- <v-icon
                      @click="openmodel(item)"
                      class="mx-1"
                      v-if="
                        orderStatus == 'Confirmed' &&
                        item.is_own &&
                        !item.is_seller
                      "
                      >mdi-pencil</v-icon
                    > -->
                  </div>
                </template>

                <!-- <template v-slot:item.deliverynote="{ item }">
                  <v-btn icon dark color="black" @click="onNoteDetails(item)">
                    <v-icon> mdi-note </v-icon>
                  </v-btn>
                </template> -->
              </v-data-table>
              <v-row justify="end"> </v-row>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <!-- Start Order confirmation modal -->
    <v-dialog v-model="menu" width="550px" scrollable>
      <v-card>
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title class="d-inline-block ml-0"
            >Product Confirmation</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <p>Product Name : {{ editedItem.product_name }}</p>
          <p>Quantity: {{ editedItem.product_quantity }}</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="menu = false" text color="red">Close</v-btn>
          <v-btn color="white" elevation="0" small @click="confirmProduct">
            <p class="green--text mb-0 font-weight-medium text-body-2">
              Confirm
            </p>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Order confirmation modal -->

    <!-- Start Edit Delivery Info -->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="deliverStatusDialog"
    >
      <v-card>
        <v-toolbar color="deep-purple darken-2" dark
          >Change Delivery Status</v-toolbar
        >
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-select
                outlined
                dense
                :items="DeliveryArray"
                v-model="delivery_status"
                item-text="delivery_status"
                return-object
                color="deep-purple darken-2"
              >
                <template v-slot:label>
                  <p class="mb-0 font-weight-bold text-h4">Change Status</p>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-dialog v-model="note_dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="deep-purple lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    block
                  >
                    Note Hint
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title
                    class="headline deep-purple lighten-3 white--text py-4 font-weight-medium"
                  >
                    Note Sample
                  </v-card-title>

                  <v-card-text>
                    <p
                      class="font-weight-bold grey--text text--darken-3"
                      v-for="(hint, index) in delivery_hints"
                      :key="index"
                    >
                      {{ hint.headline }} :
                      <span class="font-weight-regular">{{
                        hint.details
                      }}</span>
                    </p>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="note_dialog = false">
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                outlined
                name="input-7-4"
                v-model="note"
                color="deep-purple darken-2"
              >
                <template v-slot:label>
                  <p class="mb-0 font-weight-bold text-h4">Note</p>
                </template>
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red" @click="onCloseDeliveryModal" elevation="0"
            >Close</v-btn
          >
          <v-btn
            color="deep-purple darken-2"
            @click="updateDeliveryStatus"
            elevation="0"
            :disabled="
              !Object.keys(delivery_status).length || !note.trim().length
            "
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Edit Delivery Info -->

    <!-- Start Edit Payment Status-->
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="open_payment_modal"
    >
      <v-card>
        <v-toolbar color="deep-purple darken-2" dark
          >Change Payment Status</v-toolbar
        >
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select
                outlined
                dense
                :items="['Paid']"
                v-model="payment_status"
                color="deep-purple darken-2"
              >
                <template v-slot:label>
                  <p class="mb-0 font-weight-bold text-h4">Change Status</p>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red" @click="closePaymentModal" elevation="0"
            >Close</v-btn
          >
          <v-btn
            color="deep-purple darken-2"
            @click="updatePaymentStatus"
            elevation="0"
            :disabled="!payment_status"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Edit Payment Status-->

    <!-- Start Show current note details -->
    <v-dialog v-model="current_note_dialog" width="500">
      <v-card>
        <v-card-title class="headline deep-purple darken-2 py-4 white--text">
          Delivery Note Details
        </v-card-title>

        <v-card-text>
          <p v-if="current_delivery_note" class="mb-0">
            {{ current_delivery_note }}
          </p>
          <p v-else class="mb-0">No Note provided</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="current_note_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Show current note details -->

    <!-- Start Show transfer list -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline"> Hub Transfer History </v-card-title>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr style="background-color: #002441">
                  <th class="text-left font-weight-bold white--text">S/N</th>
                  <th class="text-left font-weight-bold white--text">Name</th>
                  <th class="text-left font-weight-bold white--text">
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in details_warehouse_shop_list"
                  :key="index"
                  :class="{ 'blue-grey lighten-4': index % 2 == 0 }"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.quantity }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="dialog = false" elevation="0">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Show transfer list -->

    <!-- Start Snackbar -->
    <v-snackbar v-model="snackbar" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- End Snackbar -->
  </v-container>
</template>


<script>
export default {
  data: () => ({
    confirmCondition: true,
    cancellCondition: true,

    note_dialog: false,
    current_note_dialog: false,
    loading: true,
    ripple: false,
    menu: false,
    showCancelButton: false,
    showConfirmButton: false,
    product_name: "",
    product_quantity: 0,
    product_id: -1,
    search: "",
    quantity_value: "",
    headers: [
      { text: "Product Title", value: "product_name", sortable: false },
      { text: "Quantity", value: "total_quantity", sortable: false },
      { text: "Unit Price", value: "unit_price", sortable: false },
      { text: "Sub Total", value: "total_price", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "Color", value: "product_color", sortable: false },
      { text: "Approve Status", value: "actions", sortable: false },
      { text: "Payment Status", value: "payment_actions", sortable: false },
      // { text: "Status", value: "actions", sortable: false },
      { text: "Order Status", value: "admin_status", sortable: false },
      { text: "Delivery Status", value: "deliveryaction", sortable: false },
      //   { text: "Delivery Note", value: "deliverynote", sortable: false },
    ],
    products: [],
    demo: [],
    contact: [],
    useraddress: [],
    orderInfo: {},
    editedItem: {
      product_name: "",
      product_quantity: 0,
      id: -1,
    },
    orderStatus: "",

    warehouse: [],

    shop: [],

    subtract: 0,
    subtractWarhouse: [],
    subtractShop: [],
    totalSubtractCountOfWarhouse: "0",
    totalSubtractCountOfShop: "0",

    snackbar: false,
    text: "",
    deliverStatusDialog: false,
    delivery_status: {},
    note: "",
    DeliveryArray: [],
    tempobj: {},
    delivery_hints: [
      {
        headline: "1. Pending",
        details: "Your order is now pending, please wait for more information.",
      },
      {
        headline: "2. Manager",
        details: "Your product now on manager & soon be released for shipment.",
      },
      {
        headline: "3. Shipment",
        details:
          "Your product is now on the way for shipment to the warehouse.",
      },
      {
        headline: "4. Ware Housing",
        details: "Your product reach Warehouse successfully.",
      },
      {
        headline: "5. Assorting",
        details: "Your product is now assorting & ready for weighing.",
      },
      {
        headline: "6. Weighing",
        details:
          "Your product is now in the weighting stage & soon be released for Supervision.",
      },
      {
        headline: "7. supervision",
        details:
          "Your product is now on the hand of supervision Please hold you seat.",
      },
      {
        headline: "8. Loading",
        details: "Your product is now loading & ready for shipment.",
      },
      {
        headline: "9. Transportation",
        details: "Your product is now on the way to your destination.",
      },
      {
        headline: "10. Delivered ",
        details: "Your product delivered successfully, We will miss you.",
      },
    ],
    current_delivery_note: "",

    open_payment_modal: false,
    payment_status: "",

    user: {},
    warehouse_shop_list: [],
    selectedHub: {},
    dialog: false,
    details_warehouse_shop_list: [],

    hub_id: 0,
    flag: 0,
  }),

  watch: {
    subtractWarhouse: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfWarhouse",
    },

    subtractShop: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfShop",
    },

    menu: function (newValue) {
      this.totalSubtractCountOfWarhouse = "0";
      this.totalSubtractCountOfShop = "0";
      this.subtractWarhouse.length = 0;
      this.subtractShop.length = 0;
    },
  },

  methods: {
    // To see warhouse and shop details for that product and open the model in success response
    Approve(item) {
      if (this.orderStatus == "Cancelled") {
        this.text =
          "Order is already cancelled and product can not be approved anymore !";
        this.snackbar = true;
      } else {
        this.editedItem.product_name = item.product_name;
        this.editedItem.product_quantity = item.total_quantity;
        this.editedItem.id = item.id;

        this.menu = true;
      }
    },

    // Total subtracted value count for each warhouse
    totalProductQuantitiyCountOfWarhouse() {
      this.totalSubtractCountOfWarhouse = 0;

      for (let n = 0; n < this.subtractWarhouse.length; n++) {
        if (
          Number(this.subtractWarhouse[n].subtract) > 0 &&
          Number(this.subtractWarhouse[n].subtract) <=
            Number(this.subtractWarhouse[n].quantity)
        ) {
          this.totalSubtractCountOfWarhouse =
            Number(this.totalSubtractCountOfWarhouse) +
            Number(this.subtractWarhouse[n].subtract);
        } else {
          this.subtractWarhouse.splice(n, 1);
        }
      }
    },

    // Total subtracted value count for each shop
    totalProductQuantitiyCountOfShop() {
      this.totalSubtractCountOfShop = 0;

      for (let n = 0; n < this.subtractShop.length; n++) {
        if (Number(this.subtractShop[n].subtract) > 0) {
          this.totalSubtractCountOfShop =
            Number(this.totalSubtractCountOfShop) +
            Number(this.subtractShop[n].subtract);
        } else {
          this.subtractShop.splice(n, 1);
        }
      }
    },

    // Push new warhouse to array to track selected warhouse
    warhouseSubtract(item) {
      //check array length for new item or not
      if (this.subtractWarhouse.length > 0) {
        let index = this.subtractWarhouse.findIndex(
          (warhouse) => warhouse.id == item.id
        );

        if (index != -1) {
          this.subtractWarhouse[index].subtract = item.subtract;
        } else {
          this.subtractWarhouse.push(item);
        }
      } else {
        this.subtractWarhouse.push(item);
      }

      // Count total quantitiy
      // if (this.subtractWarhouse.length == 1) {

      //   this.totalProductQuantity = this.subtractWarhouse[0].subtract;
      // } else {
      //   this.totalProductQuantity = 0;
      //   for (let n = 0; n < this.subtractWarhouse.length; n++) {
      //     if (!isNaN(Number(this.subtractWarhouse[n].subtract))) {
      //       this.totalProductQuantity =
      //         Number(this.totalProductQuantity) +
      //         Number(this.subtractWarhouse[n].subtract);
      //     }else{
      //       this.subtractWarhouse.splice(n,1)
      //     }
      //   }
      // }
    },

    // Push new shop to array to track selected shop
    shopSubtract(item) {
      if (this.subtractShop.length > 0) {
        let index = this.subtractShop.findIndex(
          (warhouse) => warhouse.id == item.id
        );
        if (index != -1) {
          this.subtractShop[index].subtract = item.subtract;
        } else {
          this.subtractShop.push(item);
        }
      } else {
        this.subtractShop.push(item);
      }
    },

    // approve specific product
    confirmProduct() {
      let index = this.products.findIndex(
        (product) => product.id == this.editedItem.id
      );

      axios
        .get(
          `hub/approve_individual_item/${this.editedItem.id}/${this.hub_id}/${this.flag}/`
        )
        .then((response) => {
          // console.log({ response });

          if (response.data.success) {
            this.menu = false;

            this.products[index].is_processing = true;
            this.totalSubtractCountOfWarhouse = 0;
            this.totalSubtractCountOfShop = 0;

            this.showCancelButton = false;
            this.showConfirmButton = true;
            this.editedItem.id = -1;
            this.text = "Hub request successfully done !";
            this.snackbar = true;

            this.getOrder();

            // let count = 0;
            // this.products.forEach((product) => {
            //   if (product.admin_status == "Approved") {
            //     count++;
            //   }
            // });

            // if (count == this.products.length) {
            //   this.orderConfirm();
            // } else {
            //   this.showCancelButton = false;
            //   this.showConfirmButton = true;
            //   this.editedItem.id = -1;
            //   this.text = response.data.message;
            //   this.snackbar = true;
            // }
          } else {
            this.menu = false;
            this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.menu = false;
          this.text = "Something went wrong";
          this.snackbar = true;
        });
    },

    orderConfirm() {
      axios
        .get(`productdetails/admin_approval/${this.$route.query.id}/`)
        .then((response) => {
          if (response.data.success) {
            //this.text = "Order approved";
            this.text = response.data.message;
            this.snackbar = true;
            this.showConfirmButton = false;
            this.showCancelButton = false;
            this.orderStatus = "Confirmed";
          } else {
            this.text = response.data.message;
            // this.text = "Something went wrong";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong";
          this.snackbar = true;
        });
    },

    // Cancel an order
    orderCancel() {
      axios
        .get(`productdetails/admin_cancellation/${this.$route.query.id}/`)
        .then((response) => {
          if (response.data.success) {
            //this.text = "Order has been Cancelled";
            // this.text = response.data.message;
            // this.snackbar = true;
            // this.showConfirmButton = false;
            // this.showCancelButton = false;
            // this.orderStatus = "Cancelled";
            window.location.reload(true);
          } else {
            this.text = response.data.message;
            //this.text = "Something went wrong";
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = "Something went wrong";
          this.snackbar = true;
        });
    },

    CancelProduct(item) {
      let index = this.products.findIndex((product) => product.id == item.id);

      axios
        .get(`hub/item_cancellation/${item.id}/`)
        .then((response) => {
          if (response.data.success) {
            this.products[index].admin_status = "Cancelled";

            // Order auto approving if all products are approved
            let count = 0;
            this.products.forEach((product) => {
              if (product.admin_status == "Cancelled") {
                count++;
              }
            });

            if (count == this.products.length) {
              this.orderCancel();
            } else {
              this.text = response.data.message;
              this.snackbar = true;
            }
          } else {
            this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.text = response.data.message;
          this.snackbar = true;
        });
    },

    // Close delivery status modal dialog and empty the object
    onCloseDeliveryModal() {
      this.delivery_status = {};
      this.note = "";
      this.deliverStatusDialog = false;
    },

    // GET delivey status list and open the modal
    openmodel(item) {
      Object.assign(this.tempobj, item);
      // Make API call
      axios
        .get(
          `ad/all_delivery_info/${this.tempobj.id}/${this.tempobj.delivery_status}/`
        )
        .then((response) => {
          if (response.data.success && response.data.data.length) {
            this.DeliveryArray = response.data.data;
            this.deliverStatusDialog = true;
          }
        });
    },

    // GET delivey status list and open the payment modal
    openPaymentmodal(item) {
      Object.assign(this.tempobj, item);
      this.open_payment_modal = true;
    },

    // Update delivery status
    updateDeliveryStatus() {
      // Construct the payload
      let payload = {
        status: this.delivery_status,
        note: this.note,
      };

      // Find the item to change
      let itemToChange = this.findAnItem(this.products, "id", this.tempobj.id);

      axios
        .post(`ad/change_delivery_status/${this.tempobj.id}/`, payload)
        .then((response) => {
          if (response.data.success) {
            itemToChange.delivery_status = this.delivery_status.delivery_status;
            this.onCloseDeliveryModal();
            this.createSnackbar("Delivery status changed successfully.");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong !");
        });
    },

    // Update payment status
    updatePaymentStatus() {
      let itemToChange = this.findAnItem(this.products, "id", this.tempobj.id);
      // Make API call to change payment status
      axios
        .get(`productdetails/change_payment_status/${this.tempobj.id}/`)
        .then((response) => {
          // console.log("AFTER PAYMENT CALL ", response.data);
          if (response.data.success) {
            itemToChange.order_status = "Paid";
            this.closePaymentModal();
          } else {
            this.closePaymentModal();
            this.createSnackbar("Something Went Wrong !");
          }
        })
        .catch((err) => {
          this.closePaymentModal();
          this.createSnackbar("Something Went Wrong !");
        });
    },

    // Close payment modal
    closePaymentModal() {
      this.open_payment_modal = false;
      this.payment_status = "";
      this.tempobj = {};
    },

    // Create snackbar
    createSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },

    // See current delivery note details
    onNoteDetails(item) {
      this.current_delivery_note = item.note;
      this.current_note_dialog = true;
    },

    // Find An item from array
    findAnItem(sourceArray, findBy, findWith) {
      let item = sourceArray.find((element) => element[findBy] == findWith);
      return item;
    },

    // GET all warehouse and shop
    getAllWarehouseShop() {
      axios
        .get(`productdetails/new_warehouse_shop_info/`)
        .then((response) => {
          // console.log({ response });
          if (response.data.success) {
            this.warehouse_shop_list = response.data.data;
          }
        })
        .catch((err) => {
          // console.log({ err });
        });
    },

    getOrder() {
      // Get details of order
      axios
        .post(`Cart/specific_order/${this.$route.query.id}/`)
        .then((res) => {
          // console.log({ res });
          this.products = res.data.data.orders;
          this.orderInfo = res.data.data;
          this.orderStatus = res.data.data.admin_status;
          // console.log('dfgsfd', res.data.data);

           if (this.orderStatus == "Processing") {
            this.showCancelButton = false;
            this.showConfirmButton = false;
          } else {
            this.showConfirmButton = true;
            this.showCancelButton = true;
          }

          // if (this.products.length > 0 && this.orderStatus == "Processing") {
          //   for (let index = 0; index < this.products.length; index++) {
          //     if (this.products[index].admin_status == "Approved") {
          //       console.log('enter')
          //       this.showCancelButton = false;
          //       this.showConfirmButton = true;
          //       break;
          //     } else {
          //       console.log('cancelled')
          //       this.showConfirmButton = false;
          //       this.showCancelButton = true;
          //     }
          //   }
          // }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // Confirm Order
    onConfirmOrder() {
      let payload = {
        hub_id: this.selectedHub.id,
        order_id: this.$route.query.id,
        flag: this.selectedHub.flag,
        admin_id: this.user.user_id,
      };

      axios
        .get(`productdetails/admin_approval/${this.$route.query.id}/`, payload)
        .then((response) => {
          if (response.data.success) {
            this.createSnackbar(response.data.message);
            this.getOrder();
          } else {
            this.createSnackbar(response.data.message);
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong !");
        });
    },

    // Item is on proccessing and admin want to see the transfer details
    onDetails(item) {
      axios
        .get(`productdetails/show_temporary_info/${item.id}`)
        .then((response) => {
          if (response.data.success && Array.isArray(response.data.data)) {
            this.details_warehouse_shop_list = response.data.data;
            this.dialog = true;
          } else {
            this.createSnackbar("Something went wrong");
          }
        })
        .catch((err) => {
          this.createSnackbar("Something went wrong");
        });
    },
  },

  created() {
    // GET the user
    this.user = JSON.parse(localStorage.getItem("user"));
    this.hub_id = this.$route.query.hub_id;
    this.flag = this.$route.query.flag;
    if (
      (this.user.role && this.user.role.toLowerCase() == "manager") ||
      this.user.role.toLowerCase() == "support"
    ) {
      // GET specific order
      this.getOrder();
    }
  },
};
</script>


<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}

.v-dialog > .v-card > .v-toolbar {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>


